import { BaseSearch } from '@bbx/common/types/UserFeed'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { findContextLinkWithId, findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { toast } from '@wh-components/core/Toast/Toast'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import React from 'react'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

export const executeLastSearchAndNavigatorToResultList = async (search: BaseSearch) => {
    const resultListSeoNavigatorLink = findContextLinkWithIdFromArray('resultListSeoNavigatorLink', search.contextLinkList)

    if (resultListSeoNavigatorLink) {
        BbxRouter.push({ href: resultListSeoNavigatorLink.uri, clientSideRouting: false })
        return
    }

    const executeContextLink = search.contextLinkList.find((link) => link.id === 'execute')
    if (executeContextLink) {
        try {
            const searchResult = await fetchFromContextLink<SearchResult>(executeContextLink)
            const seoLink = buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath)

            if (seoLink) {
                BbxRouter.push({ href: seoLink, clientSideRouting: true })
            }
        } catch (error) {
            toast(<ApiErrorAlert error={wrapInApiErrorIfNecessary(error).errorResponse} />, {
                type: 'error',
                autoClose: false,
            })
        }
    }
}
