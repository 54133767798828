import { SearchHistory } from '@bbx/common/types/UserFeed'
import { getSearchHistory } from '@bbx/search-journey/common/api/cdcApiClient'
import { executeLastSearchAndNavigatorToResultList } from '@bbx/search-journey/common/lib/executeLastSearch'
import { ActionSheet } from '@wh-components/core/ActionSheet/ActionSheet'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import TinyArrowDownIcon from '@wh-components/icons/ArrowtinyDown'
import TinyArrowUpIcon from '@wh-components/icons/ArrowtinyUp'
import { DisplayProps } from '@wh-components/system/layout'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { css } from 'styled-components'

export const useLastSearches = (verticalId?: number) => {
    const [searchHistory, setSearchHistory] = useState<SearchHistory | undefined>(undefined)
    const [profileData] = useProfileData()

    useEffect(() => {
        async function fetchSearchHistory() {
            if (isUserLoggedIn(profileData)) {
                try {
                    const searchHistoryResult = await getSearchHistory(profileData.uuid)
                    const filteredSearches = verticalId
                        ? searchHistoryResult.searches.filter((search) => search.verticalId === verticalId)
                        : searchHistoryResult.searches
                    setSearchHistory({ ...searchHistoryResult, searches: filteredSearches })
                } catch (_ignored) {}
            }
        }
        fetchSearchHistory()
    }, [profileData, verticalId])

    return { searchHistory, setSearchHistory }
}

interface SearchHistoryProps {
    searchHistory: SearchHistory | undefined
    header: string
    color: string
    taggingData: TaggingData
    openActionSheetEvent: TaggingActionEvent
    clickLastSearchEvent: TaggingActionEvent
}

export const SearchHistoryComponent: FunctionComponent<SearchHistoryProps & DisplayProps> = ({
    searchHistory,
    header,
    color,
    taggingData,
    openActionSheetEvent,
    clickLastSearchEvent,
    ...props
}) => {
    const [open, setOpen] = useState<boolean>(false)

    const lastSearches =
        searchHistory?.searches.map((entry) => {
            return {
                label: (
                    <Box display="flex" flexDirection="column" alignItems="flex-start" key={entry.timeId}>
                        <Text fontSize="m" color="palette.verydarkgrey">
                            {entry.keyword}
                        </Text>
                        <Text fontSize="xs" color="palette.grey" lineClamp={2}>
                            {entry.infoText}
                        </Text>
                    </Box>
                ),
                onClick: async () => {
                    callActionEvent(clickLastSearchEvent, taggingData)
                    await executeLastSearchAndNavigatorToResultList(entry)
                },
            }
        }) ?? []

    return (
        <Box {...props}>
            {searchHistory?.searches.length ? (
                <ActionSheet
                    items={lastSearches}
                    isOpen={open}
                    onRequestClose={() => setOpen(false)}
                    dropdownPlacement="bottom-start"
                    header={header}
                    width={{ tablet: '300px' }}
                    dropdownButtonStyles={css`
                        height: auto;
                        padding: ${(p) => p.theme.space.s}px ${(p) => p.theme.space.m}px;
                        text-align: left;
                    `}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => {
                            if (!open) {
                                callActionEvent(openActionSheetEvent, taggingData)
                            }
                            setOpen(!open)
                        }}
                        css={css`
                            pointer-events: auto;
                            cursor: pointer;
                        `}
                    >
                        <Text
                            fontSize="s"
                            fontWeight="bold"
                            color={color}
                            css={css`
                                user-select: none;
                            `}
                        >
                            Meine letzten Suchen
                        </Text>
                        {open ? (
                            <TinyArrowUpIcon size={10} marginLeft="xs" color={color} />
                        ) : (
                            <TinyArrowDownIcon size={10} marginLeft="xs" color={color} />
                        )}
                    </Box>
                </ActionSheet>
            ) : null}
        </Box>
    )
}
