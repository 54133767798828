import { LocationAutocompleteResponse } from '../LocationAutocompleteResponse'
import { BapAutocompleteCategory, BapAutocompleteEntry, BapAutocompleteResponse } from '@bbx/search-journey/common/BapAutocompleteResponse'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { concatPathWithQueryParams } from '@wh/common/chapter/lib/urlHelpers'

const querySource = { source: 'desktop' }

export const getLocationAutocompleteResponse = (
    locationAutocompleteUrl: string,
    searchString: string,
    abortSignal?: AbortSignal,
): Promise<LocationAutocompleteResponse> =>
    fetcher<LocationAutocompleteResponse>(concatPathWithQueryParams(`/${locationAutocompleteUrl}${searchString}`, querySource), {
        credentials: 'include',
        signal: abortSignal,
    }).catch(() => Promise.resolve([] as LocationAutocompleteResponse))

export const getLocationAutocompleteWithDistricts = (
    searchString: string,
    abortSignal?: AbortSignal,
): Promise<LocationAutocompleteResponse> =>
    fetcher<LocationAutocompleteResponse>(concatPathWithQueryParams(`/autocomplete/location?term=${searchString}`, querySource), {
        credentials: 'include',
        signal: abortSignal,
    }).catch(() => Promise.resolve([] as LocationAutocompleteResponse))

export const getAutocompleteOnlyCategories = async (
    searchString: string,
    abortSignal?: AbortSignal,
): Promise<BapAutocompleteCategory[]> => {
    const autocompleteResponse = await getAutocompleteSuggestions(searchString, abortSignal)

    return autocompleteResponse.filter((entry) => entry.category).map((entry) => entry.category)
}

const getAutocompleteSuggestions = (searchString: string, abortSignal?: AbortSignal): Promise<BapAutocompleteEntry[]> => {
    return fetcher<BapAutocompleteResponse>(concatPathWithQueryParams(`/autocomplete/search?v=1&term=${searchString}`, querySource), {
        credentials: 'include',
        signal: abortSignal,
    }).catch(() => Promise.resolve([] as BapAutocompleteEntry[]))
}

export const getAutocompleteSuggestionsFromLink = (
    autocompleteContextLink: ContextLink,
    searchString: string,
    abortSignal?: AbortSignal,
): Promise<BapAutocompleteEntry[]> =>
    fetcher<BapAutocompleteResponse>(
        concatPathWithQueryParams(
            `/${autocompleteContextLink.serviceName}${autocompleteContextLink.relativePath}${searchString}`,
            querySource,
        ),
        {
            credentials: 'include',
            signal: abortSignal,
        },
    ).catch(() => Promise.resolve([] as BapAutocompleteEntry[]))
