import { useCallback, useEffect, useRef, useState } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import NProgress from 'nprogress'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { toastSearchError } from '@bbx/search-journey/sub-domains/search/lib/toastSearchError'
import { useRouter } from 'next/router'
import { SearchCallback } from '../common/SearchCallback'
import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'

export const useStartPageSearch = (initialSearchResult: SearchResult, prefetchPage: NextDynamicRoute) => {
    const [currentSearchResult, setCurrentSearchResult] = useState<SearchResult>(initialSearchResult)
    const { createAbortSignal, abort } = useAbortController()
    const [isSearchInProgress, setIsSearchInProgress] = useState<boolean>(false)
    const router = useRouter()
    const routerRef = useRef(router)
    routerRef.current = router

    useEffect(() => {
        async function prefetchResultList() {
            routerRef.current.prefetch(prefetchPage)
        }

        prefetchResultList()
    }, [prefetchPage])

    const triggerSearch = useCallback(
        async (...[searchLink, additionalParams]: Parameters<SearchCallback>) => {
            abort()

            NProgress.start()
            setIsSearchInProgress(true)

            try {
                const newSearchResult = await fetchFromContextLink<SearchResult>(searchLink, additionalParams, createAbortSignal())
                setCurrentSearchResult(newSearchResult)
            } catch (error) {
                const apiError_ = wrapInApiErrorIfNecessary(error)
                if (!apiError_.isAbort) {
                    toastSearchError()
                } else {
                    return
                }
            }
            setIsSearchInProgress(false)
            NProgress.done()
        },
        [abort, createAbortSignal],
    )

    const resetContextLink = findContextLinkWithId('searchResetLink', currentSearchResult.searchContextLinks)
    const resetSearch = useCallback(() => {
        if (resetContextLink) {
            triggerSearch(resetContextLink)
        }
    }, [resetContextLink, triggerSearch])

    return {
        currentSearchResult,
        triggerSearch,
        resetSearch,
        isSearchInProgress,
        abortRequest: abort,
    }
}
