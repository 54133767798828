import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { ExpandingAutocompleteMultiselect } from '@wh-components/core/FormElements/Autocomplete/ExpandingAutocompleteMultiselect'
import { NavigatorProps } from '../../../../common/common/Navigators/NavigatorProps'
import { useDebouncedCallback } from 'use-debounce'
import { getLocationAutocompleteResponse } from '@bbx/search-journey/common/api/autocompleteApiClient'
import { useScrollNavigatorInView } from '@bbx/common/hooks/useScrollNavigatorInView'
import { SelectedNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { WidthProps } from '@wh-components/system/layout'
import { useIsiPhone } from '@wh/common/chapter/hooks/useIsiPhone'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { Box } from '@wh-components/core/Box/Box'
import { ResetAllButton } from '../../../../common/common/Navigators/ResetAllButton/ResetAllButton'
import { LocationAutocompleteSection } from '@bbx/search-journey/common/LocationAutocompleteResponse'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useAbortController } from '@wh/common/chapter/api/useAbortController'

interface GeoNavigatorProps extends NavigatorProps, WidthProps {
    label?: string
    showResetLink?: boolean
    shownInResultList?: boolean
    taggingEvent?: TaggingActionEvent
}

type Item = { areaId: number; label: string; testId: string }
type ItemsWithHeading = { heading: string; items: Item[] }

const selectedValueFromNavigatorValue = (navigatorValue: SelectedNavigatorValue) => ({
    areaId: +navigatorValue.urlParamRepresentationForValue[0].value,
    label: navigatorValue.label,
    testId: navigatorValue.label.replace(/\s/g, ''),
})

export const GeoNavigator: FunctionComponent<GeoNavigatorProps> = ({
    navigator,
    onSearch,
    width,
    minWidth,
    maxWidth,
    label,
    showResetLink = true,
    shownInResultList,
    taggingEvent,
    taggingData,
}) => {
    const isiPhone = useIsiPhone()
    const ref = useRef<HTMLDivElement | null>(null)
    // explicitly scroll to navigator because the automatic iOS scroll would only scroll to the input field, but the menu below could be hidden by the keyboard
    const { scrollNavigatorInView } = useScrollNavigatorInView(ref, false)
    const [selectedItems, setSelectedItems] = useState<Item[]>(navigator.selectedValues.map(selectedValueFromNavigatorValue))

    useEffect(() => {
        setSelectedItems((oldItems) =>
            navigator.selectedValues.map(
                (v) =>
                    oldItems.find((i) => v.urlParamRepresentationForValue.some((p) => p.value === `${i.areaId}`)) ??
                    selectedValueFromNavigatorValue(v),
            ),
        )
    }, [navigator])

    const [autocompleteItems, setAutocompleteItems] = useState<ItemsWithHeading[]>([])
    const [defaultHighlightedAutocompleteItemIndex, setDefaultHighlightedAutocompleteItemIndex] = useState(-1)

    const { createAbortSignal, abort } = useAbortController()

    const debouncedApiOnRequestAutocompleteItemsUpdate = useDebouncedCallback(async (inputValue: string, currentSelectedItems: Item[]) => {
        abort()

        const url = `${navigator.metadata?.autocompleteLink?.serviceName}${navigator.metadata?.autocompleteLink?.relativePath}`

        const autocompleteResponse = await getLocationAutocompleteResponse(url, inputValue, createAbortSignal())

        if (autocompleteResponse.length === 0) {
            setAutocompleteItems(emptyResult)
            return
        }

        const filteredItems = autocompleteResponse
            .map((section) => mapAndFilterResult(section, currentSelectedItems))
            .filter((section) => section.items.length > 0)

        setAutocompleteItems(filteredItems)
        setDefaultHighlightedAutocompleteItemIndex(0)
    }, 300)

    const mapAndFilterResult = (section: LocationAutocompleteSection, currentSelectedItems: Item[]) => ({
        heading: section.name,
        items: section.entries
            .filter((entry) => !currentSelectedItems.some((i) => entry.areaId === i.areaId))
            .map((item) => ({ ...item, testId: item.label.replace(/\s/g, '') })),
    })

    const debouncedOnRequestAutocompleteItemsUpdate = useCallback(
        (inputValue: string, currentSelectedItems: Item[]) => {
            if (!inputValue.trim()) {
                abort()
                debouncedApiOnRequestAutocompleteItemsUpdate.cancel()
                setAutocompleteItems([])
                setDefaultHighlightedAutocompleteItemIndex(-1)
                return
            }

            debouncedApiOnRequestAutocompleteItemsUpdate(inputValue, currentSelectedItems)
        },
        [debouncedApiOnRequestAutocompleteItemsUpdate, abort],
    )

    const onChange = useCallback(
        async (newSelectedItems: Item[]) => {
            setSelectedItems(newSelectedItems)
            const additionalParams: Record<string, string[]> = {}
            const paramName = navigator.urlConstructionInformation.urlParams[0].urlParameterName
            additionalParams[paramName] = newSelectedItems.map((i) => `${i.areaId}`)
            await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
        },
        [navigator, onSearch],
    )

    return (
        <Box width={width} minWidth={minWidth} maxWidth={maxWidth}>
            {(label || showResetLink) && (
                <Box display="flex" justifyContent="space-between" marginBottom="xs">
                    {label && <NavigatorLabel testId={`navigator-${navigator.id}-label`}>{label}</NavigatorLabel>}
                    {showResetLink && navigator.selectedValues.length >= 1 && (
                        <ResetAllButton
                            contextLink={navigator.resetAllInformation?.resetAllUrl}
                            navigatorId={navigator.id}
                            onSearch={onSearch}
                        />
                    )}
                </Box>
            )}
            <ExpandingAutocompleteMultiselect
                id={navigator.id}
                testId={navigator.id}
                ariaLabel={navigator.label}
                size={{ phone: 'large', tablet: 'medium' }}
                selectedItems={selectedItems}
                autocompleteItems={autocompleteItems}
                defaultHighlightedAutocompleteItemIndex={defaultHighlightedAutocompleteItemIndex}
                onRequestAutocompleteItemsUpdate={debouncedOnRequestAutocompleteItemsUpdate}
                onChange={onChange}
                placeholder={selectedItems.length === 0 ? 'PLZ/Ort eingeben' : 'weiteren Ort auswählen'}
                onExpand={() => {
                    scrollNavigatorInView()
                    if (taggingEvent) {
                        callActionEvent(taggingEvent, taggingData)
                    }
                }}
                containerRef={ref}
                drawMenuInFrontOfBorderWorkaround={{ phone: shownInResultList && isiPhone, tablet: false }}
            />
        </Box>
    )
}

const emptyResult = [
    {
        heading: 'Keine Ergebnisse',
        items: [],
    },
]
