import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { theme } from '@wh-components/core/theme'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import React, { Fragment, FunctionComponent, PropsWithChildren, useRef } from 'react'
import { css } from 'styled-components'
import { hideWhenKeyboardIsOpen } from '@wh/common/chapter/lib/hideWhenKeyboardIsOpen'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

type Props = PropsWithChildren & {
    searchResult: SearchResult
    disabled?: boolean
    clickEvent: TaggingActionEvent
    searchBoxSearchButtonOutlineActivated?: boolean
    backgroundColor?: string
}

export const StickySearchButtonMobile: FunctionComponent<Props> = ({
    searchResult,
    disabled,
    clickEvent,
    searchBoxSearchButtonOutlineActivated,
    backgroundColor = 'palette.babyseal',
    children,
}) => {
    const shadowHelperRef = useRef<HTMLDivElement | null>(null)
    const [stickyButtonReached] = useIntersectionObserver(shadowHelperRef, {
        triggerOnce: false,
    })

    return (
        <Fragment>
            <Box
                display={{ phone: 'block', tablet: 'none' }}
                position="sticky"
                bottom={0}
                width="100%"
                paddingVertical="s"
                paddingHorizontal="m"
                boxShadow={!stickyButtonReached ? '0 0 5px 0 rgba(0, 0, 0, 0.5)' : 'none'}
                backgroundColor={backgroundColor}
                css={hideWhenKeyboardIsOpen}
            >
                <Button
                    size="large"
                    width="100%"
                    disabled={disabled}
                    testId="search-submit-button"
                    onClick={() => {
                        BbxRouter.push({
                            href: buildSeoUrl(
                                findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath,
                            ),
                            clientSideRouting: true,
                            beforeRouting: () => callActionEvent(clickEvent, searchResult.taggingData),
                        })
                    }}
                    css={css`
                        box-shadow: ${searchBoxSearchButtonOutlineActivated && `0 0 0 1px ${theme.colors.palette.white}`};
                    `}
                >
                    {children}
                </Button>
            </Box>
            <Box position="absolute" height="1px" visibility="hidden" ref={shadowHelperRef} />
        </Fragment>
    )
}
